<template>
  <ul id="element-settings" class="uk-switcher uk-margin">
    <li>
      <div class="uk-form-stacked">
        <div class="uk-margin">
          <label class="uk-form-label">Inhalt</label>
          <div class="uk-form-controls">
            <textarea class="uk-textarea" rows="10" v-model="form.content"></textarea>
          </div>
        </div>
      </div>
    </li>
    <li>
      <div class="uk-width-1-1 uk-margin">
        <div>
          <general-settings :form="form" />
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import GeneralSettings from './../GeneralSettings'
export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  components: {
    GeneralSettings
  },
  data () {
    return {
      form: {
        content: ''
      }
    }
  },
  mounted () {
    this.form = this.data
  },
  methods: {
    //
  }
}
</script>
